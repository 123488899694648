import React, { useState } from "react";
import SearchIcon from "assets/serachicon.jpg";
import { Languages } from "components/QualityTools/LanguagesData";

const SourceLanguage = ({ setInputLang, containerRef, inputLang }) => {
  const [open, setOpen] = useState(false);
  const [searchLanguage, setSearchLanguage] = useState("");
  return (
    <div className="flex-1 w-full relative">
      <div
        className="cursor-pointer h-[50px] px-4 bg-white rounded-lg text-base font-semibold font-opensans border w-full border-solid border-quotetext-100
       text-[#777777] flex items-center justify-between"
        onClick={() => {
          setOpen(!open);
          setSearchLanguage("");
        }}
      >
        <p>
          {inputLang
            ? Languages?.find((lang) => lang?.code === inputLang)?.name
            : "Detect language"}
        </p>
        <span
          className={`text-quotetext-200 arrow ${
            open ? "rotate-[316deg]" : "rotate-[135deg]"
          }  transition-all duration-200 ease-linear  `}
        ></span>
      </div>

      {open && (
        <div
          className="bg-white w-full border border-[#B4B4B4] p-2 rounded-lg absolute z-50 overflow-hidden"
          style={{ height: `${containerRef?.current?.offsetHeight - 73}px` }}
        >
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <img src={SearchIcon} alt="search_icon" className="z-50" />
            </div>
            <input
              placeholder="Search language"
              className={`h-[50px] px-4 ps-10  z-51 bg-white w-full rounded-lg outline-none border-quotetext-100  border border-solid  text-[#777777] text-base flex items-center justify-between`}
              value={searchLanguage}
              onChange={(e) => setSearchLanguage(e.target.value)}
              required
            />
          </div>

          <ul
            className="rounded-bl-lg rounded-br-lg visible top-[100px] w-full bg-white overflow-y-scroll "
            style={{ height: `${containerRef?.current?.offsetHeight - 136}px` }}
          >
            {Languages?.filter(({ name }) => {
              if (searchLanguage === "") {
                return name;
              } else if (
                name.toLowerCase().includes(searchLanguage.toLowerCase())
              ) {
                return name;
              }
            })?.map(({ name, code, priority }) => (
              <li
                className={`h-[50px] px-4 w-full text-quotetext-200 flex items-center justify-between cursor-pointer ${
                  priority === 0 ? "border-b border-quotetext-100" : ""
                }`}
                onClick={() => {
                  setInputLang(code);
                  setOpen(false);
                }}
              >
                {name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SourceLanguage;
